
import { useFeatureMGMStore } from '~/stores/feature-mgm'
import { mapWritableState, storeToRefs } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useUiStore } from '@/stores/ui'
export default {
  name: 'MPProductsContainer',
  props: {
    bannerType: {
      type: String,
      default: '',
    },
    viewMode: {
      type: String,
      default: '',
    },
    showUserCreditLimit: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
    categories: {
      type: Array,
      default: () => [],
    },
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return { resolution }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['content_category']),
    isCategoriesPage() {
      return this.$route.name?.includes('categories')
    },
    isMDSM() {
      return this.resolution === 'sm' || this.resolution === 'md'
    },
    showMPFilterCategory() {
      return (
        [
          'merchant-merchant___ru',
          'merchant-merchant___az',
          'search-term___ru',
          'search-term___az',
          'tags-tag___ru',
          'tags-tag___az',
          'brands-brand___az',
          'brands-brand___ru',
        ].includes(this.$route.name) && this.listCategory?.length
      )
    },
    listCategory() {
      return this.categories.filter(cat => cat)
    },
  },
  async mounted() {
    if (!this.content_category && this.viewMode !== 'products_and_categories') {
      const dcmStore = useDcmStore()
      await dcmStore.getContentCategory()
      const featureMGMStore = useFeatureMGMStore()
      await featureMGMStore.getFeatures()
    }
  },
}
