
import pageViewGTM from '@/mixins/pageViewGTM'
import productFilters from '@/mixins/productFilters'
import { useMarketStore } from '@/stores/market'
import {
  scrollTo,
  setViewSearchResultsSetup,
  rtbAnalytics,
  decodeQuery,
  filterQueryWhiteList,
  getSearchQueries,
} from '@/utilities'
import { PER_PAGE } from '@/constants'
import setDataLayer from '~/mixins/setDataLayer'
import { storeToRefs } from 'pinia'
import { useBloomreachStore } from '@/stores/bloomreach'
import seoPopularProducts from './seo_popular_products'

export default {
  name: 'MerchantPage',

  mixins: [pageViewGTM, productFilters, setDataLayer, seoPopularProducts],
  layout: 'market',
  setup() {
    const marketStore = useMarketStore()
    const { productFilters } = storeToRefs(marketStore)
    const bloomReachStore = useBloomreachStore()
    return {
      marketStore,
      productFilters,
      bloomReachStore,
    }
  },
  async asyncData({ error, params, query, route }) {
    const marketStore = useMarketStore()
    if (route.params?.merchant) {
      const id = route.params.merchant.split('-')
      const options = {
        'include_fields[]': 'name',
      }

      marketStore.GET_MERCHANT_NAME_BY_ID({
        id: id?.[0],
        options,
      })
    }

    const promises = []

    let options = {
      per_page: PER_PAGE,
    }
    options['q[opaque_id]'] = route?.fullPath
    if (params.merchant) {
      options['q[seller_marketing_name_id_eq]'] = params.merchant
        .split('-')
        .shift()
    }
    if (query['q[manufacturer_in]'])
      options['q[manufacturer_in]'] = query['q[manufacturer_in]']
    options.include_fields = [
      'id',
      'old_price',
      'retail_price',
      'availability',
      'default_offer_id',
      'img_url_thumbnail',
      'name',
      'categories',
      'manufacturer',
      'avail_check',
      'status',
      'slugged_name',
      'discount',
      'default_marketing_name',
      'ratings',
      'offers',
      'offers,offers.retail_price',
      'offers,offers.marketing_name',
      'offers.merchant_uuid',
      'category_id',
      'product_labels',
      'loyalty_cashback',
      'default_merchant_rating',
      'offers.id',
      'offers.installment_enabled',
      'offers.max_installment_months',
      'offers.avail_check',
      'offers.partner_rating',
      'offers.uuid',
      'offers.old_price',
      'offers.seller_id',
      'offers.seller_marketing_name',
      'best_installment_offer_id',
      'qty',
      'non_refundable',
      'offers.supplier_id',
      'is_bulk_heavy',
      'default_merchant_uuid',
      'categories.path_ids',
    ].join()
    options.exclude_fields =
      'ratings.questions,ratings.assessment_id,ratings.product_id'
    options['q[search_mode]'] = 'seller'
    options['q[response_mode]'] = 'default'
    options['q[default_facets]'] = true
    if (query['q[s]']) {
      options['q[s]'] = query['q[s]']
    } else {
      options['q[s]'] = 'discount_score desc'
    }
    if (query.category) {
      options['q[category_id_in]'] = query.category
    }

    options = { ...decodeQuery(filterQueryWhiteList(query)), ...options }

    const statuses = {}
    statuses['q[status_in]'] = 'active'
    marketStore.moreProductOptions = {
      options,
      statuses,
    }
    promises.push(
      new Promise(resolve =>
        marketStore
          .getProducts({ options, statuses })
          .then(resp => resolve(resp))
          .catch(() => {
            error({ statusCode: 500, message: '' })
          }),
      ),
      new Promise(resolve => {
        const options = {
          'q[marketing_name_id_eq]': params.merchant.split('-').shift(),
          'q[tag_type]': 'seo',
          'q[opaque_id]': route?.fullPath,
        }
        if (query?.category) options['q[category_id_in]'] = query?.category

        marketStore
          .getSeoTags(options)
          .then(resp => {
            resolve(resp)
          })
          .catch(() => {
            error({ statusCode: 500, message: '' })
          })
      }),
    )
    const response = await Promise.all(promises)

    const categories = response?.[0]?.filters?.default?.find(
      filter => filter?.field === 'categories.id',
    )

    const children = categories?.buckets?.reduce(
      (accumulator, currentValue) => {
        if (currentValue.children) {
          return accumulator.concat(currentValue.children)
        } else {
          return accumulator
        }
      },
      [],
    )

    if (process.browser) {
      document.getElementsByClassName('Layout')[0].scrollTop = 0
    }
    return {
      categories: children,
    }
  },
  data() {
    return {
      source: 'seller',
    }
  },
  head() {
    const scripts = []
    const seoScript = this.getSeoPopular()
    if (seoScript) scripts.push(seoScript)
    return {
      title: this.$t('merchant_title', {
        brand: this.marketStore.merchantName,
      }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('merchant_description', {
            brand: this.marketStore.merchantName,
          }),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('merchant_title', {
            brand: this.marketStore.merchantName,
          }),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('merchant_description', {
            brand: this.marketStore.merchantName,
          }),
        },
      ],
      script: scripts,
    }
  },
  computed: {
    title_page() {
      return this.marketStore.merchantName
    },
    categoryOptions() {
      const selectedCategory =
        this.categories?.find(
          category =>
            category.id === this.marketStore.defaultCategoryMegaDiscounts,
        ) || this.title_page
      const categoryNames = selectedCategory
        ? [this.title_page, selectedCategory.name]
        : [this.title_page]

      return {
        categoryNames,
        selectedCategory,
        products: this.marketStore.products,
        partner: this.title_page,
      }
    },
  },
  watch: {
    productFilters(query) {
      const defaultQueries = { ...this.$route.query }
      Object.keys(defaultQueries).forEach(key => {
        if (/q\[([^']*)]/.test(key)) {
          delete defaultQueries[key]
        }
      })
      this.$router
        .replace({ query: { ...defaultQueries, ...query } })
        .then(() => {
          this.viewItemList()
        })
    },
    $route: {
      handler() {
        this.marketStore.loadingProducts = false
        this.productFiltersFunc()

        this.$nextTick(() => {
          this.bloomReachStore.viewCategory(this.categoryOptions)
        })
      },
      deep: true,
    },
  },
  watchQuery: true,
  async mounted() {
    scrollTo(document.getElementsByClassName('Layout')[0], 0, 100)
    this.$root.$on('mp-clear-filters', cleared => {
      if (cleared) {
        const query = getSearchQueries({
          routeQueries: this.$route?.query,
        })
        this.$router.push(
          this.localePath({
            name: 'brands-brand',
            params: {
              brand: this.$route.params.brand,
            },
            query,
          }),
        )
      }
    })
    if (!this.marketStore.minInstallmentAmount) {
      await this.marketStore.getMinInstallmentAmount()
    }
    this.viewItemList()
    rtbAnalytics(this.$i18n.locale, {
      eventType: 'placebo',
    })
    setViewSearchResultsSetup(
      this.$route,
      this.marketStore.pagination?.total_entries,
      this.$i18n.locale,
    )
    if (this.$route?.query.from_search) {
      this.source = 'search'
    }

    this.bloomReachStore.viewCategory(this.categoryOptions)
  },
}
