
import { useMarketStore } from '~/stores/market'

export default {
  name: 'MPSellersTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  computed: {
    isSearch() {
      return this.$route.query.category
    },
    selectedCategoryName() {
      return this.selectedCategory?.name
    },
    selectedCategorylink() {
      return this.localePath({
        name: 'categories-slug',
        params: {
          slug: `${this.selectedCategory?.id}-${this.selectedCategory?.slugged_name}`,
        },
      })
    },
    selectedCategory() {
      const category = this.marketStore.filters.default.find(
        filter => filter.field === 'categories.id',
      )
      return this.findNested(
        category?.buckets?.[0]?.children,
        'id',
        +this.$route.query.category,
      )
    },
  },
  methods: {
    findNested(obj, key, value) {
      if (obj[key] === value) {
        return obj
      } else {
        for (const i in Object.keys(obj)) {
          if (typeof obj[i] === 'object') {
            const found = this.findNested(obj[i], key, value)
            if (found) {
              // If the object was found in the recursive call, bubble it up.
              return found
            }
          }
        }
      }
    },
  },
}
